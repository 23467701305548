<template>
  <div class="page">
    <a-row :gutter="10" class="custom__row">
      <a-col span="6">
        <ul class="grid__box">
          <li v-for="(el, index) in list" :key="index">
            <div
              class="border com_box"
              @click="openKey('1', el)"
              v-if="el.type === 'sign_module'"
            >
              <img src="../../assets/dg_navigation.png" alt="" />
              <h4>店招设置</h4>
            </div>
            <div
              class="border com_box"
              @click="openKey('2', el)"
              v-if="el.type === 'card_module'"
            >
              <img src="../../assets/deco_card.png" alt="" />
              <h4>会员卡</h4>
            </div>
            <div
              class="border com_box"
              @click="openKey('3', el)"
              v-if="el.type === 'text_module'"
            >
              <img src="../../assets/dg_text.png" alt="" />
              <h4>文本模块</h4>
            </div>
            <div
              class="border com_box"
              @click="openKey('4', el)"
              v-if="el.type === 'nav_module'"
            >
              <img src="../../assets/dg_guide.png" alt="" />
              <h4>图片导航</h4>
            </div>
            <div
              class="border com_box"
              @click="openKey('5', el)"
              v-if="el.type === 'carousel_module'"
            >
              <img src="../../assets/dg_rotation.png" alt="" />
              <h4>轮播图</h4>
            </div>
            <div
              class="border com_box"
              @click="openKey('6', el)"
              v-if="el.type === 'graphic_module'"
            >
              <img src="../../assets/text_image.png" alt="" />
              <h4>左图右文</h4>
            </div>
            <div
              class="border com_box"
              @click="openKey('7', el)"
              v-if="el.type === 'goods_module'"
            >
              <img src="../../assets/deco_goods.png" alt="" />
              <h4>商品列表</h4>
            </div>
          </li>

          <li>
            <a-popover
              title="添加组件"
              placement="right"
              v-model="clicked"
              @visibleChange="handleClickChange"
              trigger="click"
               @cancel="clicked= false"
            >
              <div slot="content">
                <a-select style="width: 120px" v-model="checedComponent">
                  <a-select-option value="sign_module">
                    店招设置
                  </a-select-option>
                  <a-select-option value="card_module">
                    会员卡
                  </a-select-option>
                  <a-select-option value="text_module">
                    文本模块
                  </a-select-option>
                  <a-select-option value="nav_module">
                    图片导航
                  </a-select-option>
                  <a-select-option value="carousel_module">
                    轮播图
                  </a-select-option>
                  <a-select-option value="graphic_module">
                    左图右文
                  </a-select-option>
                  <a-select-option value="goods_module">
                    商品列表
                  </a-select-option>
                </a-select>
                <a-button style="margin-left: 4px" @click="clicked = false"
                  >取消</a-button
                >
                <a-button
                  type="primary"
                  style="margin-left: 4px"
                  @click="addComponents"
                  >确认</a-button
                >
              </div>
              <div class="border com_box" @click="clicked = true">
                <a-icon type="plus" />
                <h4>添加组件</h4>
              </div>
            </a-popover>
          </li>
        </ul>
      </a-col>
      <a-col span="8">
        <div class="show__box">
          <div v-for="(el, index) in list" :key="index">
            <previewComponent
              :typeStr="el.type"
              :info="el.content"
            ></previewComponent>
          </div>
        </div>
      </a-col>
      <a-col span="10">
        <div class="form__box">
          <shop
            :info="current.content"
            v-if="activeKey === '1'"
            @refresh="getPreview"
            @refreshDel="refreshDel"
          ></shop>
          <card
            :info="current.content"
            v-if="activeKey === '2'"
            @refresh="getPreview"
            @refreshDel="refreshDel"
          ></card>
          <nav-modules
            :info="current.content"
            @refresh="getPreview"
            v-if="activeKey === '3'"
            @refreshDel="refreshDel"
          ></nav-modules>
          <img-nav
            :info="current.content"
            @refresh="getPreview"
            v-if="activeKey === '4'"
            @refreshDel="refreshDel"
          ></img-nav>
          <carousel
            :info="current.content"
            v-if="activeKey === '5'"
            @refresh="getPreview"
            @refreshDel="refreshDel"
          ></carousel>
          <leftImgRightText
            :info="current.content"
            v-if="activeKey === '6'"
            @refresh="getPreview"
            @refreshDel="refreshDel"
          ></leftImgRightText>
          <goods-list v-if="activeKey === '7'" :info="current.content" @refresh="getPreview"
            @refreshDel="refreshDel"></goods-list>
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "PageDecoration",
  data() {
    return {
      info: {},
      activeKey: "",
      list: [],
      current: {},
      clicked: false,
      checedComponent: "",
    };
  },
  components: {
    shop: () => import("./shop"),
    card: () => import("./card"),
    carousel: () => import("./carousel"),
    navModules: () => import("./navModules"),
    imgNav: () => import("./imgNav"),
    previewComponent: () => import("./previewComponent"),
    leftImgRightText: () => import("./leftImgRightText"),
    goodsList: () => import('./goodsList')
  },
  created() {
    // this.getInfo();
    this.getPreview();
  },
  methods: {
    refreshDel () {
      this.current = {}
      this.activeKey = ''
      this.getPreview();
    },
    addComponents() {
      this.clicked = false;
      console.log(this.checedComponent, "checedComponent");
      if (!this.checedComponent) {
        this.$message.warn('请选择组件')
        return
      }
      let obj = {
        sign_module: "/sign_modules/",
        card_module: "/card_modules/",
        text_module: "/text_modules/",
        nav_module: "/nav_modules/",
        carousel_module: "/carousel_modules/",
        graphic_module: "/graphic_modules/",
        goods_module: "/goods_modules/",
      };
      let url = obj[this.checedComponent];
      this.$axios.post(url, { index: this.list.length + 1 }).then(() => {
        this.getPreview();
      });
    },
    handleClickChange() {},
    openKey(key, obj) {
      console.log(key, obj, '---')
      this.activeKey = key;
      this.current = obj;
    },
    getPreview() {
      this.$axios.get("/shop_modules/").then((res) => {
        console.log(res, "list");
        this.list = res.modules || [];
      });
    },
    // getInfo() {
    //   this.$axios.get("/shop_decorations/").then((res) => {
    //     console.log(res);
    //     this.info = res;
    //   });
    // },
  },
};
</script>

<style scoped lang="less">
.custom__row {
  height: 100%;
}

.border {
  border: 1px solid #ddd;
}
.grid__box {
  display: flex;
  flex-flow: row wrap;
  // justify-content: space-between;
  align-content: flex-start;
  width: 240px;
  height: 80vh;
  border: 1px solid #ddd;
  overflow: hidden;
  overflow-y: auto;
  padding-top: 20px;
  > li {
    width: 100%;
    // height: 100px;
    padding: 4px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    // margin-bottom: 20px;
    h4 {
      margin: 0;
      width: 4em;
    }
  }
  .com_box {
    // width: 100px;
    width: 100%;
    height: 50px;
    background: #f2f2f2;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
  }
}
.form__box {
  height: 80vh;
  border: 1px solid #ddd;
  overflow: hidden;
  overflow-y: auto;
  padding: 10px;
  padding-bottom: 60px;
}
.show__box {
  height: 80vh;
  border: 1px solid #ddd;
  overflow: hidden;
  overflow-y: auto;
  // padding: 10px;
  // width: 375px;
}
</style>
